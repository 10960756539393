import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Autocomplete from "react-autocomplete"
import debounce from "lodash/debounce"
import map from "lodash/map"
import extend from "lodash/extend"
import cloneDeep from "lodash/cloneDeep"
import i18n from "i18next"

import { FormInput, ImageUpload, EmailInput } from "../Common"
import CompanyFormAutocompleteMenu from "./CompanyFormAutocompleteMenu"
import CompanyFormAutocompleteItem from "./CompanyFormAutocompleteItem"

import { GenericFormInput, renderGenericPlacesAutocomplete } from "../GenericInputs"

import Session from "../../helpers/SessionHelper"

import { Autocomplete as AutocompleteAPI } from "../../api"

import PhoneInput from "../PhoneInput"

import { defaultRenderOptions } from "../../utils"
import "./CompanyForm.css"
import CompanyFormAutocompleteSection from "./CompanyFormAutocompleteSection"

export default class CompanyForm extends Component {
  static propTypes = {
    mode: PropTypes.oneOf([ "autocomplete", "form" ]),
    canCreateCompanyFromAutocomplete: PropTypes.bool,
    triggerAutocompleteChangeDebounce: PropTypes.number,
    defaultData: PropTypes.object,
    onChangeMode: PropTypes.func,
    getValidationState: PropTypes.func,
    getErrorMessage: PropTypes.func,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    schoolId: PropTypes.number,
    showAvatarInput: PropTypes.bool,
    changeModeOnSelect: PropTypes.bool,
    autocompleteBySchoolId: PropTypes.bool,
    showJobteaserId: PropTypes.bool,
    showSectorUuid: PropTypes.bool,
    companySectors: PropTypes.array,
  }

  static defaultProps = {
    mode: "form",
    canCreateCompanyFromAutocomplete: true,
    triggerAutocompleteChangeDebounce: 350,
    onChangeMode: () => true,
    defaultData: {
      company_name: "",
      company_tax_id: null,
      company_activity: "",
      company_street_address: "",
      company_city: "",
      company_postal_code: "",
      company_phone_number: "",
      company_representative_name: "",
      company_representative_email_address: "",
      company_representative_position: "",
      company_country: "",
      company_sector_uuid: null,
    },
    getValidationState: () => null,
    getErrorMessage: () => null,
    editable: true,
    onChange: () => true,
    schoolId: null,
    showAvatarInput: false,
    changeModeOnSelect: false,
    autocompleteBySchoolId: true,
    showJobteaserId: false,
    showSectorUuid: false,
    companySectors: [],
  }

  constructor(props) {
    super(props)
    const formValue = (props.defaultData && props.defaultData.company_name) || ""
    this.state = {
      mode: props.mode,
      formValue,
      companyItems: this.companyItemsToSections(this.getDefaultCompanies(), formValue),
    }
    this.triggerAutocompleteChange = debounce(this.triggerAutocompleteChange, props.triggerAutocompleteChangeDebounce)
    this.avatarUpload = React.createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
      nextState.formValue !== this.shouldComponentUpdate.formValue ||
      JSON.stringify(nextState.companyItems) !== JSON.stringify(this.state.companyItems)
    )
  }

  getDefaultCompanies() {
    const { schoolId } = this.props
    return map(cloneDeep(Session.linkedCompanies.filter(company => company.school_id === schoolId && !!company.tax_id)), comp =>
      extend(comp, { linked: true })
    )
  }

  renderAutocompleteInput({ ref, ...props }) {
    return <FormInput { ...props } inputRef={ ref } autoComplete="new-password" />
  }

  renderAutocompleteMenu(items, value, style) {
    const { canCreateCompanyFromAutocomplete } = this.props
    return (
      <div>
        <CompanyFormAutocompleteMenu canCreateCompanyFromAutocomplete={ canCreateCompanyFromAutocomplete } style={ style }>
          {items}
        </CompanyFormAutocompleteMenu>
      </div>
    )
  }

  async triggerAutocompleteChange() {
    const { schoolId, autocompleteBySchoolId } = this.props
    if (this.state.formValue.length > 0) {
      const [ data, dataFromCleabit ] = await Promise.all([
        autocompleteBySchoolId
          ? AutocompleteAPI.autocompleteCompanies(this.state.formValue, schoolId)
          : Promise.resolve({}),
        AutocompleteAPI.autocompleteCompaniesFromClearbit(this.state.formValue),
      ])
      const companyItems = [
        ...this.getDefaultCompanies().filter(
          company =>
            company.name.toLocaleLowerCase().includes(this.state.formValue.toLocaleLowerCase()) ||
            company.tax_id.toLocaleLowerCase().includes(this.state.formValue.toLocaleLowerCase())
        ),
        ...data,
        ...dataFromCleabit.map(({ logo, name }, index) => ({
          logo_url: logo,
          name,
          isSuggestion: true,
          id: `${name}-${index}`,
        })),
        {
          logo_url: null,
          name: this.state.formValue,
          isSuggestion: true,
          id: this.state.formValue,
        },
      ]
      this.setState({
        companyItems: this.companyItemsToSections(companyItems, this.state.formValue),
      })
    } else {
      this.setState({ companyItems: this.companyItemsToSections(this.getDefaultCompanies()) })
    }
  }

  onInputChange(e) {
    this.setState({ formValue: e.target.value }, () => this.triggerAutocompleteChange())
  }

  onSelect = (value, item) => {
    const { onChange, onChangeMode, changeModeOnSelect } = this.props

    if (changeModeOnSelect) {
      onChangeMode()
    }
    if (!item.isSuggestion) {
      onChange(item)
      this.setState({ formValue: value })
    } else {
      if (!changeModeOnSelect) {
        onChangeMode()
      }
      onChange({
        name: item.name,
        logo_url: item.logo_url,
      })
      this.setState({
        mode: "form",
      })
    }
  }

  onInputBlur() {
    if (this.props.defaultData.name) {
      this.setState({ formValue: this.props.defaultData.name })
    } else {
      this.setState({ formValue: "", companyItems: this.companyItemsToSections(this.getDefaultCompanies()) })
    }
  }

  companyItemsToSections = (companyItems, formValue = "") => {
    const companyItemsCpy = companyItems.slice()
    const defaultCompanies = companyItems.filter(
      company =>
        company.linked && company.name && company.tax_id &&
        (company.name.toLocaleLowerCase().includes(formValue.toLocaleLowerCase()) ||
          company.tax_id.toLocaleLowerCase().includes(formValue.toLocaleLowerCase()))
    )
    companyItemsCpy.filter(company => !!company.tax_id).forEach((company, index) => {
      if (!company.linked && companyItems.findIndex(comp => comp.linked && comp.id === company.id) >= 0) {
        companyItems.splice(index, 1)
      }
      return true
    })
    const linkedSection = {
      sectionTitle: i18n.t("spa:company.autocomplete.sections.titles.linked_companies"),
      data: defaultCompanies,
      id: 0,
    }
    const notLinkedSection = {
      sectionTitle: i18n.t("spa:company.autocomplete.sections.titles.not_linked_companies"),
      data: [],
      id: 1,
    }
    const suggestionsSection = {
      sectionTitle: i18n.t("spa:company.autocomplete.sections.titles.suggested"),
      data: [],
      id: 2,
    }

    companyItems.forEach((elem) => {
      if (elem.isSuggestion) suggestionsSection.data.push(elem)
      else if (!defaultCompanies.find(company => company.id === elem.id) && !!elem.tax_id) {
        notLinkedSection.data.push(elem)
      }
    })

    return [ linkedSection, notLinkedSection, suggestionsSection ]
  }

  renderAutocomplete() {
    const { companyItems } = this.state
    return (
      <div>
        <Autocomplete
          autoHighlight={ false }
          items={ companyItems }
          renderItem={ item => (
            <div key={ item.id }>
              <CompanyFormAutocompleteSection title={ item.sectionTitle } data={ item.data } onSelect={ this.onSelect } />
            </div>
          ) }
          renderInput={ this.renderAutocompleteInput }
          getItemValue={ item => item.data }
          inputProps={ {
            withLabel: true,
            label: `${i18n.t("spa:company.autocomplete.keywords.label")} *`,
            controlId: "companyAutocompleteInput",
            onBlur: () => this.onInputBlur(),
          } }
          value={ this.state.formValue }
          onChange={ e => this.onInputChange(e) }
          wrapperStyle={ { width: "100%", position: "relative" } }
          renderMenu={ (items, value, style) => this.renderAutocompleteMenu(items, value, style) }
          menuStyle={ {
            position: "absolute",
            backgroundColor: "white",
            top: 42,
            left: 0,
            width: "60%",
          } }
        />
      </div>
    )
  }

  onChangeCreateInput = (e) => {
    const { onChange } = this.props

    onChange(e.target.name, e.target.value)
  }

  handlePhoneInput = (name, value) => {
    const { onChange } = this.props

    onChange(name, value)
  }

  renderPlaceAutocompleteInput() {
    const { getValidationState, getErrorMessage, editable, defaultData: companyData, onChange } = this.props

    return renderGenericPlacesAutocomplete({
      keyPrefix: "company",
      getValidationState,
      getErrorMessage,
      data: companyData,
      commonInputProps: {
        onChange: this.onChangeCreateInput,
        disabled: !editable,
        withLabel: true,
        required: true,
      },
      onChange,
    })
  }

  renderGenericFormInput({ name, ...props }) {
    const { getValidationState, getErrorMessage, editable, defaultData: companyData } = this.props
    const prefixedKey = `company_${name}`

    return (
      <GenericFormInput
        name={ name }
        value={ companyData[name] || companyData[prefixedKey] || "" }
        data={ companyData }
        getValidationState={ getValidationState }
        getErrorMessage={ getErrorMessage }
        editable={ editable }
        baseTranslationKey="company.form"
        onChange={ e => this.onChangeCreateInput(e) }
        { ...props }
      />
    )
  }

  triggerAvatarUpload() {
    return this.avatarUpload.current.triggerUpload()
  }

  renderCreationForm() {
    const { showAvatarInput, defaultData, companySectors, showJobteaserId, showSectorUuid } = this.props

    return (
      <Fragment>
        {showAvatarInput && (
          <ImageUpload
            defaultSource={ defaultData.logo_url }
            label={ i18n.t("spa:company.form.avatar.label") }
            ref={ this.avatarUpload }
          />
        )}
        {[
          { name: "name", required: true },
          { name: "tax_id", required: true },
          {
            name: "sector_uuid",
            visible: showSectorUuid,
            componentClass: "select",
            required: true,
            onChange: (e) => {
              const { onChange } = this.props

              onChange(e.target.name, e.target.value)
            },
            renderOptions: () =>
              defaultRenderOptions({
                options: companySectors,
                keyProperty: "uuid",
                valueKey: "uuid",
                contentKey: "name",
                withDefaultOption: true,
              }),
          },
          { name: "activity" },
          { isPlaceAutocomplete: true },
          { name: "phone_number", component: PhoneInput, onChange: this.handlePhoneInput },
          { name: "jobteaser_id", visible: showJobteaserId && Session.isSchoolAdmin() },
          { isSeparator: true },
          { name: "representative_name", required: true },
          { name: "representative_position", required: true },
          { name: "representative_email_address", component: EmailInput, required: true },
        ].map((elem, idx) => {
          if (elem.isPlaceAutocomplete) {
            return React.cloneElement(this.renderPlaceAutocompleteInput(), { key: idx })
          } else if (elem.isSeparator) {
            return <div className="company-form__separator" key={ idx } />
          } else if (!elem.hasOwnProperty("visible") || elem.visible) {
            return React.cloneElement(this.renderGenericFormInput(elem), { key: idx })
          }
          return null
        })}
        <label>{ i18n.t("spa:company.form.representative_email_address.information") }</label>
      </Fragment>
    )
  }

  render() {
    const { mode } = this.state

    switch (mode) {
      case "autocomplete":
        return this.renderAutocomplete()
      case "form":
        return this.renderCreationForm()
      default:
        return null
    }
  }
}
